<!--
 * @Author: wxb
 * @Date: 2021-08-19 13:36:56
 * @LastEditTime: 2021-08-19 16:11:09
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Graph\index.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='5'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>图谱关系</el-breadcrumb-item>
      </el-breadcrumb>

    </div>
    <div class="graph-content">
      <iframe :src="graphUrl"
              scrolling="no"
              class="graph-content-iframe"></iframe>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
export default {
  name: 'graph',
  data() {
    return {
      graphUrl: 'http://192.168.1.141:91/#/graph1/26?548&graphid=17&entity=%E7%BA%A2%E6%97%97-1'
    }
  },
  components: {
    headerNav,
    footerNav
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.graph-content {
  text-align: center;
  &-iframe {
    width: 97vw;
    height: calc(100vh - 140px);
    margin: 0 auto;
  }
}
</style>
